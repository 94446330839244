$col-alert-dark: #96092b;
$col-alert-light: #db0b3d;
$col-gray-100: #f7f9f9;
$col-gray-200: #e8efee;
$col-gray-300: #c8dad8;
$col-gray-400: #a7c0bf;
$col-gray-500: #96acab;
$col-gray-600: #5c7272;
$col-gray-700: #2b3939;
$col-gray-800: #1a2526;
$col-gray-900: #000a0c;
$col-gray-dark: #696765;
$col-pink: #ffc6d4;
$col-primary-100: #c0efef;
$col-primary-200: #8fe3ec;
$col-primary-300: #45d1e1;
$col-primary-400: #3cc0db;
$col-primary-500: #16a3cf;
$col-primary-600: #008fc1;
$col-primary-700: #00576b;
$col-primary-800: #002d38;
$col-primary-900: #001f24;
$col-secondary-100: #ceffec;
$col-secondary-500: #00d584;
$col-warning1-dark: #b25900;
$col-warning1-light: #f3881d;
$col-warning2-dark: #b6a900;
$col-warning2-light: #eded15;
$col-white: #ffffff;
$col-white-half: #ffffff7f;
$col-power-low: #7a7a85;
$col-power-10k: #6e97b8;
$col-power-25k: #55b555;
$col-power-52k: #b59f10;
$col-power-132k: #b55d00;
$col-power-220k: #c73030;
$col-power-310k: #b54eb2;
$col-power-550k: #00c1cf;
$col-power-hvdc: #4e01b5;
$col-power-traction: #a8b596;
$col-power-underground: #7a7a85;
