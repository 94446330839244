@use '../abstracts/' as *;

div.MuiSelect-select {
  font-family: $montserrat;
}

// Tables

th.MuiTableCell-root,
td.MuiTableCell-root {
  font-family: $montserrat;
  padding: $size-1;
  background-color: $col-gray-100;
  span.MuiCheckbox-root.Mui-checked,
  span.MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: $col-primary-300;
  }
}

tr.MuiTableRow-root {
  position: relative;
}

th.MuiTableCell-head {
  border-bottom: none;
}

thead.MuiTableHead-root th {
  font-weight: 600;
}

div.MuiTablePagination-root p {
  font-family: $montserrat;
}

li.MuiTablePagination-menuItem {
  font-family: $montserrat;
}

// Select

li.MuiMenuItem-root {
  font-family: $montserrat;
}

div.MuiSelect-select {
  padding: $size-0 $size-1;
  color: $col-primary-600;
  font-weight: 600;
  &:focus {
    background-color: transparent;
  }
  &#selectHandlerSelect {
    font-weight: 400;
    color: $col-gray-100;
  }
}

div.MuiPaper-root.MuiTableContainer-root {
  box-shadow: none;
}

div.MuiPaper-root.MuiMenu-paper {
  background-color: $col-gray-100;
}

div.MuiInputBase-root {
  background-color: transparent;
  &.Mui-focused {
    background-color: transparent;
  }
  &:hover {
    background-color: transparent;
  }
}

div.MuiMenu-paper {
  border-radius: $size-1;
}

div.MuiFormControl-root {
  width: 100%;
}

li.Mui-selected {
  color: $col-primary-600;
  font-weight: 600;
}

svg.MuiSvgIcon-root {
  fill: $col-primary-600;
}

.MuiSelect-nativeInput {
  color: $col-primary-600;
  font-weight: 600;
}

// Toggle button

.MuiToggleButtonGroup-root {
  display: flex;
  align-items: center;
  &.geocodingSearch {
    width: 100%;
    color: $col-gray-100;
    text-transform: none;
    margin-bottom: 0;
    white-space: nowrap;
    button {
      height: auto;
      border: none;
      font-family: $montserrat;
      font-size: 13px;
    }
    .MuiToggleButton-root {
      width: 100%;
      color: $col-gray-100;
      text-transform: none;
      margin-bottom: 0;
      &.Mui-selected {
        color: $col-primary-600;
        font-weight: 600;
      }
    }
  }
  &.selectHandler {
    width: 100%;
    color: $col-gray-900;
    text-transform: none;
    margin-bottom: 0;
    white-space: nowrap;
    button {
      height: auto;
      border: none;
      font-family: $montserrat;
      font-size: 13px;
    }
    .MuiToggleButton-root {
      width: 100%;
      color: $col-gray-100;
      text-transform: none;
      margin-bottom: 0;
      font-size: $size-2;
      &.Mui-selected {
        color: $col-primary-300;
        font-weight: 600;
        background-color: transparent;
      }
    }
  }
  &.coordinatesConverter {
    width: fit-content;
    button {
      height: auto;
      border: none;
      font-family: $montserrat;
      font-size: 15px;
      padding: $size-0 $size-2;
      &:not(:last-child) {
        margin-right: $size-1;
      }
    }
    .MuiToggleButton-root {
      width: auto;
      background-color: $col-gray-300;
      color: $col-gray-dark;
      text-transform: none;
      font-weight: 600;
      margin-bottom: 0;
      border-radius: $size-1;
      overflow: hidden;
      &.Mui-selected {
        background-color: $col-gray-dark;
        color: $col-gray-100;
        font-weight: 400;
      }
      &.MuiToggleButtonGroup-grouped {
        border-radius: $size-2;
      }
    }
  }
}

.MuiToggleButtonGroup-root,
.MuiToggleButtonGroup-grouped {
  width: auto;
  text-transform: none;
  margin-bottom: 0;
  border-radius: $size-1;
  overflow: hidden;
  font-family: $montserrat !important;
  color: $col-primary-600;
}

// Checkboxes
span.MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
span.MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: $col-primary-300;
}

.MuiCheckbox-root.Mui-disabled .MuiSvgIcon-root {
  opacity: 0.5;
}

span.MuiFormControlLabel-label {
  white-space: nowrap;
  font-family: $montserrat;
}

// Pagination

div.MuiPaper-root.MuiTablePagination-root,
div.MuiToolbar-root.MuiTablePagination-toolbar {
  background-color: $col-gray-100;
  position: unset !important;
}

div.MuiTablePagination-actions,
.MuiButtonBase-root.MuiIconButton-root {
  display: flex;
}

div.MuiPaper-root.MuiTableContainer-root {
  box-shadow: none;
}

// DataGrid

div.MuiDataGrid-root {
  max-width: 90vw;
  overflow: auto;
  border: none;
  font-family: $montserrat !important;
}

div.MuiDataGrid-toolbarContainer,
div.MuiDataGrid-panelFooter {
  button.MuiButtonBase-root,
  .MuiButton-root,
  .MuiButton-text,
  .MuiButton-textPrimary {
    font-family: $montserrat;
    font-weight: 600;
    color: $col-primary-600;
  }
}

div.MuiDataGrid-toolbarContainer,
div.MuiFormControl-root,
.MuiInputBase-root,
.MuiInput-root {
  font-family: $montserrat !important;
}

input .MuiFormLabel-root,
.MuiInputLabel-root {
  font-family: $montserrat !important;
  ::placeholder {
    font-family: $montserrat !important;
  }
}
span.MuiButtonBase-root,
.MuiSwitch-switchBase,
.MuiSwitch-input {
  color: $col-primary-600 !important;
}

.MuiInput-root:after {
  border-bottom: 2px solid $col-primary-600 !important;
}

.Mui-focused {
  color: $col-primary-600 !important;
}

.MuiTypography-root {
  font-family: $montserrat !important;
}

div.MuiDataGrid-columnHeaders {
  border-bottom: 2px solid $col-gray-300;
}

.MuiDataGrid-iconSeparator {
  color: $col-primary-600;
}

svg.MuiSvgIcon-root {
  color: $col-primary-600;
}

div.MuiDataGrid-columnHeaderTitle {
  font-weight: 600;
  border-bottom: 1px solid $col-gray-300;
}

div.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none;
}

div.MuiDataGrid-row.customSelected {
  background-color: $col-primary-300 !important;
}
