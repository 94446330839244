@use 'styles/abstracts' as *;

.container {
  padding-right: $size-2;
  padding-bottom: $size-2;
  margin-top: $size-2;
  min-width: 400px;
  display: flex;
  flex-flow: column;
}

button.unselected,
button.selected {
  background-color: $col-gray-100;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  border-radius: 0;
  &:not(:last-child) {
    margin-bottom: $size-1;
  }
  &:hover {
    background-color: $col-gray-300;
  }
}

button.selected {
  color: $col-primary-600;
  font-weight: 800;
}
