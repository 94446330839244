$size-000: 1px;
$size-00: 2px;
$size-0: 4px;
$size-1: 8px;
$size-2: 16px;
$size-3: 24px;
$size-4: 32px;
$size-5: 40px;
$size-6: 48px;
$size-7: 56px;
$size-8: 64px;
$size-9: 72px;
$size-10: 150px;

$uploadColumnsAlert: 364px;
$popup-height: 300px;
$popup-width: 400px;
