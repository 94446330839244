@use 'styles/abstracts' as *;

.cropImageModal {
  height: 470px;
  display: flex;
  flex-direction: column;
}

.crop {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: $size-2 $size-2 $size-2 $size-1;
  width: 400px;
  max-height: 450px;
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  input {
    display: none;
  }
  button {
    display: flex;
    margin: $size-1;
  }
  .customUpload {
    height: $size-6;
    display: inline-block;
    background-color: $col-gray-200;
    color: $col-gray-900;
    padding: $size-2 $size-1;
    cursor: pointer;
    border-radius: $size-1;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    label {
      p {
      }
    }
    &:hover {
      background-color: $col-gray-200;
      filter: brightness(0.9);
    }
  }
}

.images {
  align-items: center;
  justify-content: center;
  margin-top: $size-1;
  display: flex;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    width: 300px;
    background-color: $col-gray-900;
    border-radius: $size-1;
  }
  .cropper {
    width: fit-content;
    height: fit-content;
    max-height: 300px;
    max-width: 300px;
  }
  img {
    object-fit: contain;
  }
  .canvas {
    display: none;
  }
}

.created {
  margin: auto;
  padding-right: $size-2;
  padding-bottom: $size-2;
}

