@use 'src/styles/abstracts' as *;

.notice {
  margin: $size-1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 350px;
  background-color: $col-gray-100;
  padding: $size-1 $size-2;
  border-radius: $size-1;
  pointer-events: auto;
  box-shadow: 5px 5px 10px rgba($col-gray-900, 0.2);
}

.icon {
  display: flex;
  align-items: center;
}

.message {
  display: flex;
  align-items: center;
  margin-left: $size-1;
}

.buttons {
  display: flex;
  align-items: center;
  zoom: 0.5;
  justify-self: flex-end;
}
