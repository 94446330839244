@use 'styles/abstracts' as *;

.form {
  display: flex;
  flex-flow: column;
  width: 400px;
  padding-right: $size-2;
  padding-bottom: $size-2;
  .containerPhoto {
    display: flex;
    margin-top: $size-1;
    margin-bottom: $size-2;
  }
  .profilePic {
    background-color: $col-gray-300;
    width: 96px;
    height: 96px;
    display: flex;
    margin-right: $size-1;
    border-radius: $size-1;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: $size-1;
    }
  }
}
.fields {
  display: flex;
  flex-flow: column;
  margin-bottom: $size-3;
  .field {
    position: relative;
    display: flex;
    flex-flow: column;
    margin-bottom: $size-2;
    border-bottom: solid 1px $col-gray-300;
    label {
      margin-bottom: $size-0;
      color: $col-gray-300;
    }
    input {
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 18px;
    }
    .icon {
      position: absolute;
      right: 0;
      bottom: 0;
      cursor: pointer;
      user-select: none;
    }
    .invalid {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 12px;
      transform: translateY(calc(100% + #{$size-0}));
      &.alert {
        color: $col-alert-light;
      }
      &.warning {
        color: $col-gray-dark;
      }
    }
    .disabled {
      color: $col-gray-300;
    }
  }
}
.buttons {
  display: flex;
  justify-content: flex-end;
  button:not(:last-child) {
    margin-right: $size-1;
  }
}

.noDisplay {
  display: none;
}
