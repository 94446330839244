@use 'styles/abstracts' as *;

.navbar {
  position: absolute;
  top: $size-2;
  right: $size-2;
  z-index: 1;
}

.container {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  .actions {
    margin-left: $size-1;
    justify-content: center;
    background-color: rgba($col-gray-900, 0.6);
    backdrop-filter: blur(calc($size-3 - $size-0));
    border-radius: $size-1;
    padding: $size-0;
  }
}
