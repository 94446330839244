@use '../abstracts/' as *;

body {
  font-family: $montserrat;
  font-size: 1rem;
  font-weight: 300;
  b {
    font-weight: 600;
  }
}

h1 {
  font-family: $vietnam;
  font-size: 3.75rem;
  font-weight: 700;
}

h2 {
  font-family: $vietnam;
  font-size: 2.75rem;
  font-weight: 700;
}

h3 {
  font-family: $vietnam;
  font-size: 2rem;
  font-weight: 700;
}

h4 {
  font-family: $vietnam;
  font-size: 1.5rem;
  font-weight: 700;
}

h5 {
  font-family: $vietnam;
  font-size: 1.125rem;
  font-weight: 700;
  b {
    color: $col-gray-600;
  }
}

p {
  font-family: $montserrat;
  font-size: 1.125rem;
  font-weight: 300;
  b {
    font-weight: 600;
  }
}
