@use '../abstracts' as *;

select {
  border: none;
  padding: $size-0;
  font-family: $montserrat;
  border-radius: $size-1;
  font-weight: 600;
  box-shadow: 5px 5px 10px rgba($col-gray-900, 0.2);
  outline: none;
}
