@use 'styles/abstracts' as *;

.container {
  height: 100vh;
  width: 100vw;
  position: relative;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: rgba($col-gray-900, 0.2);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 240px !important;
    width: 240px !important;
  }
}

