@use '../abstracts' as *;

button {
  cursor: pointer;
  border: none;
  outline: none;
  transition: all 0.3s ease;
  height: $size-6;
  font-family: $montserrat;
  font-size: 18px;
  overflow: hidden;
  &.dark {
    background-color: $col-gray-dark;
    color: $col-gray-100;
    &.active {
      color: $col-primary-600;
    }
  }
  &.light {
    background-color: $col-gray-100;
    color: $col-gray-900;
  }
  &.mediumLight {
    background-color: $col-gray-200;
    color: $col-gray-900;
  }
  &.primary {
    background-color: $col-primary-600;
    color: $col-gray-100;
  }
  &.secondary {
    background-color: $col-gray-300;
    color: $col-gray-900;
  }
  &.alert {
    background-color: $col-alert-light;
    color: $col-gray-100;
  }
  &.active {
    background-color: $col-gray-dark;
    color: $col-gray-100;
    box-shadow: inset 0 0 0 10em rgba($col-gray-100, 0.3);
  }
  &.transparent {
    background-color: transparent;
    color: $col-gray-100;
  }
  &.text {
    padding: $size-1 $size-2;
    border-radius: $size-1;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: $size-1;
    }
  }
  &.icon {
    width: $size-6;
    border-radius: $size-1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    transition: all 0.3s ease;
    &.dark,
    &.primary,
    &.secondary,
    &.transparent {
      box-shadow: inset 0 0 0 10em rgba($col-gray-100, 0.3);
    }
    &.light {
      background-color: $col-gray-300;
    }
    &.mediumLight {
      background-color: $col-gray-300;
    }
    &:disabled {
      box-shadow: none;
      cursor: initial;
    }
  }
  &:disabled {
    opacity: 0.5;
    svg {
      filter: opacity(0.5);
    }
  }
  &.customDisabled {
    background-color: transparent;
    color: $col-gray-100;
    &:disabled {
      background-color: transparent;
      color: $col-gray-100;
      opacity: 1;
    }
    svg {
      filter: opacity(1);
    }
  }
}
