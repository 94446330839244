@use 'styles/abstracts' as *;

.container {
  *:hover {
    & + .tooltip {
      display: block;
    }
  }
}

.tooltip {
  color: $col-gray-900;
  z-index: 1;
  display: none;
  position: absolute;
  background-color: $col-gray-100;
  padding: $size-1;
  border-radius: $size-1;
  font-size: 12px;
  box-shadow: $size-0 $size-0 $size-1 rgba($col-gray-900, 0.2);
}
