@use 'styles/abstracts' as *;

.card {
  border: 1px solid $col-gray-300;
  display: flex;
  background-color: $col-gray-100;
  border-radius: $size-1;
  padding: $size-1 $size-2;
  p {
    margin: 0;
    &.userName {
      font-weight: bold;
      color: $col-gray-900;
    }
    &.role {
      color: $col-gray-500;
      text-transform: capitalize;
    }
  }
  hr {
    margin: $size-1 0;
  }
  .picture {
    width: $size-6;
    height: $size-6;
    margin-top: $size-0;
    margin-right: $size-2;
    img {
      width: 100%;
      border-radius: $size-1;
    }
  }
}
