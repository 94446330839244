@use 'styles/abstracts' as *;

.form {
  padding-right: $size-1;
  padding-bottom: $size-2;
}

.fields {
  display: flex;
  flex-flow: column;
  min-width: 400px;
}

.field {
  display: flex;
  flex-flow: column;
  position: relative;
  margin-bottom: $size-2;
  padding-right: $size-2;
  label {
    color: $col-gray-300;
    font-size: $size-2;
    margin-bottom: $size-1;
    margin-top: $size-1;
  }
  input {
    border: none;
    outline: none;
    background-color: transparent;
    font-size: $size-2;
    margin-bottom: 0;
    border-bottom: solid 1px $col-gray-300;
    padding: $size-0;
    &:disabled {
      color: $col-gray-300;
    }
  }
  .invalid {
    position: absolute;
    bottom: 0;
    right: $size-2;
    font-size: 12px;
    transform: translateY(100%);
    color: $col-alert-light;
  }
  .select {
    position: relative;
    select {
      border: none;
      font-size: $size-2;
      width: 100%;
      color: $col-primary-600;
      font-weight: 600;
      outline: none;
      border: 1px solid $col-gray-300;
      padding: $size-1 $size-2;
      appearance: none;
      :checked {
        background-color: $col-gray-200;
        font-weight: 600;
      }
    }
    span {
      position: absolute;
      right: $size-2;
      top: calc(50% + #{$size-0});
      transform: translateY(-50%);
    }
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  font-size: $size-2;
  margin-top: $size-2;
  button:not(:last-child) {
    margin-right: $size-1;
  }
}
